.annotation {
  position: relative;
  cursor: pointer;
  padding: 1px 2px;
}

.annotation:hover {
  border: 1px solid black;
}

.annotation .label {
  position: absolute;
  top: -1.25rem;
  left: -1px;
  font-size: 0.8em;
  padding: 2px 4px;
  border-radius: 3px 3px 0 0;
  display: none;
  border: 1px solid black;
  border-bottom: none;
  text-wrap: nowrap;
}

.annotation:hover .label {
  display: block;
}

h1, h2, h3, h4, h5, h6, strong {
  background: inherit;
}