.preloader-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-container.fullscreen {
  position: absolute;
  left: 0;
  right: 0;
  height: 80vh;
  padding-bottom: 50px;
}

.preloader-container.partial {
  height: 80vh;
}

.loader-ellipsis,
.loader-ellipsis div {
  box-sizing: border-box;
}
.loader-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: rgb(7, 26, 67);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.loader-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

