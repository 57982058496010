.progress-container {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 20px 0;
  box-sizing: border-box;
  flex-direction: column;
}

.progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: #02023f2f;
  box-sizing: border-box;
  border: 1px solid #02023f;
  margin-bottom: 5px;
}

.progress-filler {
  border-radius: 10px;
  height: 100%;
  background: #02023f;
}